@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px;
        margin: 1.75rem auto;
    }
}

.modal-content {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    vertical-align: auto;
}
.custom-modal {
    size: inherit;
    width: 500%;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.city{
    width: 50px;
    height: 50px;
    background-image: url('https://voorent-new.s3.ap-south-1.amazonaws.com/images/icons.svg');
    display: block;
}

.noida{
    background-position: 0 -300px;
    background-repeat: no-repeat;
}

.bangalore {
    background-position: 0 0;
    background-repeat: no-repeat;
}

.mumbai{
    background-position: 0 -250px;
    background-repeat: no-repeat;
}

.pune{
    background-position: 0 -350px;
    background-repeat: no-repeat;
}

.delhi{
    background-position: 0 -100px;
    background-repeat: no-repeat;
}

.gurgoan{
    background-position: 0 -150px;
    background-repeat: no-repeat;
}

.hyderabad{
    background-position: 0 -200px;
    background-repeat: no-repeat;
}

.chennai{
    background-position: 0 -50px;
    background-repeat: no-repeat;
}

.select-city{
    padding:10px;
    cursor: pointer;
}

.select-city:hover{
    background: #f1f1f1;
    border-radius: 4px;
    padding:10px;
}